import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../api/login';
import { FONT_STYLE, PAGES_SLUGS } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';
import RegisterSuccess from './RegisterSuccess';

const NewClientForm = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Registo submetido com sucesso',
    errorMessage: '',
  });
  const [formData, setFormData] = useState({
    name: undefined,
    phone: undefined,
    company: undefined,
    taxNumber: undefined,
    email: undefined,
    street: undefined,
    zipCode: undefined,
    city: undefined,
    country: undefined,
    password: undefined,
    confirmPassword: undefined,
    fastRegister: false,
  });
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((values) => ({ ...values, [name]: value }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    registerUser(formData)
      .then(() => {
        setIsLoading(false);
        setShowConfirmation(true);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(({ response: { status, data } }) => {
        const errorMessage = data?.msg || data?.errors?.[0]?.msg;
        if (status === 401) {
          navigate('/login');
        } else if (status === 400 || status > 401) {
          setIsLoading(false);
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: errorMessage,
          }));
        }
      });
  }

  if (showConfirmation) {
    return <RegisterSuccess />;
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      autoComplete='off'
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: FONT_STYLE.bold,
        }}
      >
        Registo de nova conta de cliente
      </Typography>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label='Nome'
          type='text'
          name='name'
          required
          value={formData.name}
          onChange={handleChange}
          error={formData.name === ''}
          helperText={formData.name === '' && 'Campo obrigatório.'}
        />
        <TextField
          fullWidth
          label='Contacto'
          type='text'
          name='phone'
          required
          value={formData.phone}
          onChange={handleChange}
          error={formData.phone === ''}
          helperText={formData.phone === '' && 'Campo obrigatório.'}
        />
      </Box>

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label='Empresa'
          type='text'
          name='company'
          required
          value={formData.company}
          onChange={handleChange}
          error={formData.company === ''}
          helperText={formData.company === '' && 'Campo obrigatório.'}
        />
        <TextField
          fullWidth
          label='NIF'
          type='text'
          name='taxNumber'
          required
          value={formData.taxNumber}
          onChange={handleChange}
          error={formData.taxNumber === ''}
          helperText={formData.taxNumber === '' && 'Campo obrigatório.'}
        />
      </Box>

      <TextField
        fullWidth
        label='Email'
        type='email'
        name='email'
        required
        value={formData.email}
        onChange={handleChange}
        error={formData.email === ''}
        helperText={formData.email === '' && 'Campo obrigatório.'}
      />

      <TextField
        fullWidth
        label='Morada'
        type='text'
        name='street'
        required
        value={formData.street}
        onChange={handleChange}
        error={formData.street === ''}
        helperText={formData.street === '' && 'Campo obrigatório.'}
      />
      <TextField
        fullWidth
        label='País'
        type='text'
        name='country'
        required
        value={formData.country}
        onChange={handleChange}
        error={formData.country === ''}
        helperText={formData.country === '' && 'Campo obrigatório.'}
      />

      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label='Código Postal'
          type='text'
          name='zipCode'
          required
          value={formData.zipCode}
          onChange={handleChange}
          error={formData.zipCode === ''}
          helperText={formData.zipCode === '' && 'Campo obrigatório.'}
        />
        <TextField
          fullWidth
          label='Cidade'
          type='text'
          name='city'
          required
          value={formData.city}
          onChange={handleChange}
          error={formData.city === ''}
          helperText={formData.city === '' && 'Campo obrigatório.'}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: { xs: 'block', sm: 'flex' },
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: { xs: 'block', sm: 'flex' },
            gap: 2,
          }}
        >
          <TextField
            fullWidth
            required
            name='password'
            label='Palavra-passe'
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            error={formData.password === ''}
            helperText={formData.password === '' && 'Campo obrigatório.'}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <FiEye size={18} />
                    ) : (
                      <FiEyeOff size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            required
            name='confirmPassword'
            label='Confirmar Palavra-passe'
            type={showConfirmationPassword ? 'text' : 'password'}
            value={formData.confirmPassword}
            error={
              formData.confirmPassword === '' ||
              formData.confirmPassword !== formData.password
            }
            helperText={
              formData.confirmPassword === ''
                ? 'Campo obrigatório.'
                : formData.confirmPassword !== formData.password
                ? 'As palavras-passe não coincidem.'
                : null
            }
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      setShowConfirmationPassword(!showConfirmationPassword)
                    }
                  >
                    {showConfirmationPassword ? (
                      <FiEye size={18} />
                    ) : (
                      <FiEyeOff size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Typography
          sx={{
            width: '100%',
            fontSize: '0.8rem',
            marginTop: 1,
          }}
        >
          (A password deve conter no mínimo 8 caracteres com pelo menos 1 letra
          maiúscula, 1 letra minúscula, 1 algarismo e 1 caracter especial)
        </Typography>
      </Box>

      <Box
        sx={{
          width: 'inherit',
          marginTop: 2,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={() =>
                setTermsAndConditionsAccepted(!termsAndConditionsAccepted)
              }
            />
          }
          label={
            <Typography
              variant='small'
              sx={{
                '& a': {
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: `Li e aceito de acordo com os  
                <a
                  href='/${PAGES_SLUGS.institutional.termsAndConditions}'
                  target='_blank'
                  title='Termos e Condições'
                >
                  Termos e Condições
                </a>
            `,
              }}
            />
          }
        />
      </Box>

      <LoadingButton
        variant='contained'
        type='submit'
        title='Submeter registo'
        loading={isLoading}
        sx={{
          marginTop: 3,
        }}
        disabled={
          formData.confirmPassword !== formData.password ||
          !termsAndConditionsAccepted
            ? true
            : false
        }
      >
        Submeter registo
      </LoadingButton>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default NewClientForm;
