import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateAddress } from '../../../api/user';
import { compareTwoObjects, objectIsEmpty } from '../../../helpers';
import { slugify } from '../../../helpers/slugify';
import { setAddresses } from '../../../state/userSlice';
import { MyAccountTitle } from '../../../styles';
import PopupNotification from '../../PopupNotification/PopupNotification';

const AddressesForm = ({ openNewAddressModal, addresses }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addIdToAddresses = (address) =>
    address.map((address) => {
      const { city, country, zipCode, street } = address;
      const id = `${street}-${city}-${zipCode}-${country}`;
      const idSlugifyed = slugify(id);
      return { ...address, oldStreet: street, id: idSlugifyed };
    });

  const [userAddresses, setUserAddresses] = useState(
    addIdToAddresses(addresses)
  );
  const [shownAddress, setShownAddress] = useState(userAddresses[0]);
  const [selectedAddressDefault, setSelectedAddressDefault] = useState(
    userAddresses[0]
  );
  const [addressChanged, setAddressChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Alterações guardadas com sucesso',
    errorMessage: '',
  });

  useEffect(() => {
    setShownAddress(userAddresses[0]);
  }, [userAddresses]);

  useEffect(() => {
    setUserAddresses(addIdToAddresses(addresses));
  }, [addresses, dispatch]);

  //  check IF exists differences between 'default address selected' AND 'changed addres'
  useEffect(() => {
    const addressDifferences = compareTwoObjects(
      shownAddress,
      selectedAddressDefault
    );
    const addressHasDifferences = !objectIsEmpty(addressDifferences);

    if (addressHasDifferences) {
      if (!addressChanged) setAddressChanged(true);
    } else {
      if (addressChanged) setAddressChanged(false);
    }
  }, [shownAddress, selectedAddressDefault, addressChanged]);

  function handleSelectedAddress(e) {
    const { value } = e.target;

    const newAddress = userAddresses.find(({ id }) => id === value);

    setSelectedAddressDefault(newAddress);
    setShownAddress(newAddress);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setShownAddress((values) => ({ ...values, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    updateAddress(shownAddress)
      .then((addresses) => {
        dispatch(setAddresses(addresses));
        setIsLoading(false);
        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status > 401) {
            const errorMessage = errors.reduce(
              (prevValue, { msg }) => prevValue + `${msg}. `,
              ''
            );

            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: errorMessage,
            }));

            setIsLoading(false);
          }
        }
      );
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '100%', lg: '50%' },
      }}
    >
      <FormControl fullWidth>
        <InputLabel>Morada selecionada</InputLabel>
        <Select
          label='Morada selecionada'
          name='selectedAddress'
          value={shownAddress?.id}
          onChange={handleSelectedAddress}
        >
          {userAddresses.map((address) => {
            const { street, id } = address;
            return (
              <MenuItem
                key={id}
                value={id}
              >
                {street}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <MyAccountTitle>Editar morada selecionada</MyAccountTitle>

        <TextField
          fullWidth
          multiline
          required
          type='text'
          name='street'
          label='Morada'
          value={shownAddress.street}
          onChange={handleChange}
          error={!shownAddress.street}
          helperText={!shownAddress.street && 'Campo obrigatório.'}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 0, md: 2 },
          }}
        >
          <TextField
            type='text'
            name='zipCode'
            label='Código Postal'
            required
            value={shownAddress.zipCode}
            onChange={handleChange}
            error={!shownAddress.zipCode}
            helperText={!shownAddress.zipCode && 'Campo obrigatório.'}
            sx={{ flex: 1 }}
          />

          <TextField
            type='text'
            name='city'
            label='Cidade'
            required
            value={shownAddress.city}
            onChange={handleChange}
            error={!shownAddress.city}
            helperText={!shownAddress.city && 'Campo obrigatório.'}
            sx={{ flex: 1 }}
          />
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: `calc(50% - ${theme.spacing(1)})` },
          }}
        >
          <TextField
            fullWidth
            type='text'
            name='country'
            label='País'
            required
            value={shownAddress.country}
            onChange={handleChange}
            error={!shownAddress.country}
            helperText={!shownAddress.country && 'Campo obrigatório.'}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginTop: 3,
          }}
        >
          <Button
            onClick={() => openNewAddressModal(true)}
            variant='contained'
            color='secondary'
            title='Adicionar morada'
            endIcon={<FiPlus size={14} />}
          >
            Adicionar morada
          </Button>

          <LoadingButton
            variant='contained'
            type='submit'
            title='Guardar alterações'
            disabled={
              !shownAddress.street ||
              !shownAddress.zipCode ||
              !shownAddress.city ||
              !shownAddress.country ||
              !addressChanged
                ? true
                : false
            }
            sx={{
              display: 'flex',
            }}
            loading={isLoading}
          >
            Guardar alterações
          </LoadingButton>
        </Box>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default AddressesForm;
